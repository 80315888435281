@import "../Assets/Style/shared/colors";
@import "../Assets/Style/shared/fonts";
$blue2: #03a2ff;
#about {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  .para {
    margin: 0 0 0 5%; }
  .logo_cont {
    margin: 3% 5% 0 0; }
  @media only screen and (max-width: 1300px) {
    flex-direction: column;
    .para {
      margin: 0 0 5% 0; }
    .logo_cont {
      margin: 3% 5% 5% 0; } }

  a {
    background-size: 0.06em 0.06em;
    background-repeat: repeat-x;
    background-position-y: 93%;
    background-position-x: 0;
    color: $black;
    text-decoration: none;
    transition: 0.15s;
    &.link {
      color: $blue;
      font-weight: 900 !important;
      &:hover {
        color: $blue2; } }

    span {
      font-weight: 700 !important; }
    .com {
      color: #777; }
    .dot {
      color:  #777; }
    .at {
      color: #777; }
    .poly {
      color: #777; }
    .mail {
      font-weight: 800 !important; } }
  a:active {
    color: #555;
    text-decoration: none; }


  a:hover {
    color: $blue2;
    .com {
      color: $blue2; }
    .dot {
      color: $blue2; }
    .at {
      color: $blue2; }
    .poly {
      color: $blue2; } } }





