@keyframes in {
  0% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  5.31% {
    transform: matrix3d(1.113, 0, 0, 0, 0, 1.113, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  10.51% {
    transform: matrix3d(1.178, 0, 0, 0, 0, 1.178, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  15.82% {
    transform: matrix3d(1.205, 0, 0, 0, 0, 1.205, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  21.02% {
    transform: matrix3d(1.21, 0, 0, 0, 0, 1.21, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  35.34% {
    transform: matrix3d(1.202, 0, 0, 0, 0, 1.202, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  49.55% {
    transform: matrix3d(1.2, 0, 0, 0, 0, 1.2, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  78.18% {
    transform: matrix3d(1.2, 0, 0, 0, 0, 1.2, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1.2, 0, 0, 0, 0, 1.2, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

@keyframes out {
  0% {
    transform: matrix3d(1.2, 0, 0, 0, 0, 1.2, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  5.31% {
    transform: matrix3d(1.087, 0, 0, 0, 0, 1.087, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  10.51% {
    transform: matrix3d(1.022, 0, 0, 0, 0, 1.022, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  15.82% {
    transform: matrix3d(0.995, 0, 0, 0, 0, 0.995, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  21.02% {
    transform: matrix3d(0.99, 0, 0, 0, 0, 0.99, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  35.34% {
    transform: matrix3d(0.998, 0, 0, 0, 0, 0.998, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  49.55% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  78.18% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

.ic {
  height: 22px;
  width: 22px;
  margin: 0 1em;
}

.ic .inn {
  transition: 0.1s;
}
#board.ic .inn {
  fill:  #fabd05;
}
#contact.ic .inn {
  fill: #ee3034;
}
#coffee.ic .inn {
  fill:  #0169bd;
}
#infor.ic .inn {
  fill:  #aeb0b2;
}

.ic .out {
  fill:  #121212;
  transition: 0.1s;
}
#canada.ic {
  height: 13px !important;
  width: 13px !important;
  margin: 0;
}
#canada.ic .ends {
  fill: #D52B1E;
}
#canada.ic .mid {
  fill: #eeeeee;
}

.header {
  align-self: left;
  display: flex;
  justify-content: left;
  position: fixed;
}

.header h1 {
  margin-bottom: 0.1rem;
  font-family: 'Barlow', sans-serif;
  color: #121212;
  text-align: left;
}

.header h4 {
  margin-top: 0.1rem;
  font-family: 'Alatsi', sans-serif;
  font-size: 1em;
  font-weight: 700;
  color: #7f7f7f;
  text-align: center;
}

a {
  background-size: 0.06em 0.06em;
  background-repeat: repeat-x;
  background-position-y: 93%;
  background-position-x: 0;
  text-decoration: none;
}

a:active, a:hover {
  text-decoration: none;
}

a:active .ic .inn, a:hover .ic .inn {
  fill: #121212;
}

a:active .ic .out, a:hover .ic .out {
  fill: #121212;
}

a .ic {
  height: 22px;
  width: 22px;
  margin: 0 1em;
}

a .ic .inn {
  fill: #aeb0b2;
  transition: 0.1s;
}

a .ic .out {
  fill: #121212;
  transition: 0.1s;
}
