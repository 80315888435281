@import '../../Assets/Style/shared/animation';
@import "../../Assets/Style/shared/fonts";
@import "../../Assets/Style/shared/colors";
#foot {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: left;
    font-family: $rob;
    font-size: 12px;
    font-weight: 800;
    width: inherit;
    grid-area: footer;
    a {
        margin: 0 0.5em;
        color: $black;
        transition: 0.25s;
        &:hover {
            color: $blue; } } }
