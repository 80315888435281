
body,
html {
    margin: 0;
    padding: 0;
    background: #fff;
    overflow: hidden;

}

*,
*:before,
*:after {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}



.container {
    position: relative;
    width: inherit;
    height: inherit;
    grid-area: cont;
  }
  
  .page {
    position: absolute;
    width: inherit;
    height: inherit;
  }
  
  .page-enter {
    opacity: 0;
    transform: scale(1.025);
  }
  
  .page-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }
  
  .page-exit {
    opacity: 1;
    transform: scale(1);
  }
  
  .page-exit-active {
    opacity: 0;
    transform: scale(0.975);
    transition: opacity 300ms, transform 300ms;
  }
  
  .p5Canvas {
      z-index: -100;
  }