@import "../../Assets/Style/shared/colors";
@import "../../Assets/Style/shared/fonts";

$mark_small: 8px;
$mark_mid: 12px;
$mark_large: 18px;

#logo {
  color: $black;
  display: flex;
  flex-direction: row;

  align-items: center;
  align-content: baseline; }

#type {
  font-family: $hro;
  color: $black;
  &.small {
    font-size: 12px;
    font-weight: 800 !important;
    margin: $mark_large * 0  $mark_small * 0  $mark_small * 0  $mark_small * 0.5; }
  &.large {
    font-size: 54px;
    font-weight: 800 !important;
    margin: $mark_large * 1  $mark_small * 0  $mark_small * 0  $mark_small * 0;
    @media only screen and (max-width: 600px) {
      font-size: 26px; } }
  &.logo {
    font-size: 24px;
    font-weight: 900 !important;
    transform: rotate(90deg);
    margin: $mark_large * 0  $mark_small * 0  $mark_small * 0  $mark_small * 0; } }

#mark {
  display: flex;
  flex-direction: row;
  &.mid {
    width: $mark_mid * 2;
    height: $mark_mid * 3; }
  &.large {
    width: $mark_large * 2;
    height: $mark_large * 3;
    margin: $mark_large * 0 $mark_large * 0 $mark_large * 0 $mark_large * 1;
    @media only screen and (max-width: 600px) {
      width: $mark_mid * 2;
      height: $mark_mid * 3; } }
  &.logo {
    width: $mark_small * 2;
    height: $mark_small * 3;
    margin: $mark_small * 0 $mark_small * 2 $mark_small * 0 $mark_small * 0; } }
.s {
  &.mid {
    width: $mark_mid;
    height: $mark_mid; }
  &.large {
    width: $mark_large;
    height: $mark_large;
    @media only screen and (max-width: 600px) {
      width: $mark_mid;
      height: $mark_mid; } }
  &.logo {
    width: $mark_small;
    height: $mark_small; } }

#s4 {
  background: $yellow;
  border-radius: 0 0 20% 0; }

#s3 {
  background: $black;
  border-radius: 20% 20% 0 0; }

#s1 {
  background:  $blue;
  border-radius: 20% 0 0 0; }

#s2 {
  background: $red;
  border-radius: 0 0 20% 20%; }

.sg {
  display: flex;
  flex-direction: column;
  .large {
    width: $mark_large;
    height: $mark_large * 3;
    @media only screen and (max-width: 600px) {
      width: $mark_mid;
      height: $mark_mid * 3; } }
  .mid {
    width: $mark_mid;
    height: $mark_mid * 3; }
  .logo {
    width: $mark_small;
    height: $mark_small * 3; } }
